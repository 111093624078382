import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import VitaComp from "../components/vita/vita-comp";
import Contact from "../components/contact/contact";
import PriceList from "../components/news/price-list";
import News from "../components/news/news";
import Content from "../components/content/content";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const { content } = data;
    const siteTitle = data.site.siteMetadata.title;
    const banner = data.banner;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Melanie Rogge-Blank"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Img style={{ height: "61.8vh", maxHeight: "400px" }} fluid={banner.fluid} alt={"Home"}/>
        <div className="wrapper">
          {content.nodes.map((node, i) => <Content content={node} key={node.name} odd={i % 2 > 0}/>)}
          <PriceList/>
          <News/>
          <VitaComp/>
          <Contact/>
        </div>

      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }

        banner: contentfulAsset(title: {eq: "BannerFoto"}) {
          fluid(quality: 100, maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp
            }
        }

      content: allContentfulKursbeschreibungen(sort: {fields: order}) {
            nodes {
                image {
                    fluid {
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
                description {
                    json
                }
                name
            }
        }
    }
`;
