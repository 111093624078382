import React from "react";
import styles from "./news.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import moment from "moment";
import localization from "moment/locale/de";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default () => {
  const data = useStaticQuery(graphql`
      query {
          scheduling: allContentfulTermine(sort: {fields: [timeTo]}){
              edges{
                  node{
                      timeFrom
                      timeTo
                      day
                      programm {
                          description
                      }
                  }
              }
          }
          newsContent: contentfulAktuelleInfosNewsContentRichTextNode {
              json
          }
      }
  `);

  return (
    <div id="aktuelles" className={styles.newsCon}>
      <h2>Aktuelles</h2>
      {documentToReactComponents(data.newsContent.json)}
      <h3 id="termine">Termine</h3>
      <div className={styles.schedCon}>{
        createSchedulingPlan(data.scheduling).map(scheduleEntry => {
          return (<div key={scheduleEntry.day} className={styles.entryDayCon}>
            <h3>{scheduleEntry.day}</h3>
            <ul>
              {
                scheduleEntry.scheduling.map(term => {
                  return (<li key={`${term.timeFrom}-${term.timeTo}`}>
                    <span className={styles.termTime}>{`${term.timeFrom} - ${term.timeTo} Uhr`}</span>
                    <p className={styles.termDescription}>{term.program.description}</p>
                  </li>);
                })
              }
            </ul>
          </div>);
        })
      }
      </div>
    </div>
  );
}


const createSchedulingPlan = (data) => {
  moment.locale("de", localization);
  let weekdays = moment.weekdays();

  let weeklyScheduling = {};
  data.edges
    .map(edge => edge.node)
    .forEach(val => {
      let schedule = {
        timeTo: val.timeTo,
        timeFrom: val.timeFrom,
        program: val.programm,
      };

      if (val.day in weeklyScheduling) {
        weeklyScheduling[val.day].scheduling.push(schedule);
      } else {
        weeklyScheduling[val.day] = { scheduling: [schedule] };
      }
    });

  return Object.keys(weeklyScheduling).map(weekday => {
    return {
      day: weekday,
      scheduling: weeklyScheduling[weekday].scheduling,
    };
  }).sort((a, b) => weekdays.indexOf(a.day) - weekdays.indexOf(b.day));
};
