import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default () => {
  const priceList = useStaticQuery(
    graphql`
        query  {
            allContentfulPreisliste(sort: {fields: price}) {
                edges {
                    node {
                        type
                        price
                    }
                }
            }
        }
    `,
  ).allContentfulPreisliste.edges;

  return (
    <div style={{ padding: 30 }}>
      <h2>Preisliste</h2>
      <table>
        <thead>
        <tr>
          <td>Art</td>
          <td>Preis</td>
        </tr>
        </thead>
        <tbody>
        {priceList.map(({ node }) => <tr key={node.type}>
          <td>{node.type}</td>
          <td>{node.price} €</td>
        </tr>)}
        </tbody>
      </table>
    </div>
  );
}
