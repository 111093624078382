import React from "react";
import styles from "./contact.module.scss";
import { graphql, useStaticQuery } from "gatsby";

export default () => {
  const { contact } = useStaticQuery(graphql`
      query  {
          contact: contentfulKontakt {
              fullName
              phone
              mobile
              email
              location
          }
      }
  `);

  return (
    <div className={styles.contactCon} id="kontakt">
      <h3>Kontakt</h3>
      <div>
        <div>
          Kursort: {contact.location}
        </div>
        <address>
          {contact.fullName}<br/>
          Tel.: {contact.phone}<br/>
          Mobil: {contact.mobile}<br/>
          E-Mail: {contact.email}<br/>
        </address>
      </div>
    </div>
  );
}
