import React, { Fragment } from "react";
import styles from "./vita-comp.module.scss";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";


export default () => {
  const data = useStaticQuery(graphql`
      query VitaQuery {
          vitaImage: contentfulAsset(title: {eq: "MelanieFoto"}) {
              fluid {
                  ...GatsbyContentfulFluid_withWebp
              }
          }
          #     Dynamic static queries not possible in Gatsby...
          vitaContent: contentfulVita(name: {eq: "Melanie Rogge-Blank"}) {
              content {
                  json
              }
          }
      }
  `);
  return (
    <Fragment>
      <h2 id="vita" style={{ textAlign: "center", marginBottom: 0 }}>Vita</h2>
      <div className={styles.vitaCon}>
        <div className={styles.vitaImg}>
          <Img fluid={data.vitaImage.fluid}/>
        </div>
        <div className={styles.vitaInfoCon}>
          {documentToReactComponents(data.vitaContent.content.json)}
        </div>
      </div>
    </Fragment>);
}
