import React from "react";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styles from "./content.module.scss";

export default (props) => {
  return (
    <div id={props.name}>
      <div className={styles.content}>
        <div className={styles.contentInfoCon} style={{ flexWrap: props.odd ? "wrap" : "wrap-reverse" }}>
          <div className={props.odd ? styles.contentImgLeft : styles.contentImgRight}>
            <Img style={{ borderRadius: "1%" }} fluid={props.content.image.fluid}/>
          </div>
          <div className={styles.contentDescriptionCon}>
            {documentToReactComponents(props.content.description.json)}
          </div>
        </div>
      </div>
    </div>
  );
}
